<template>
  <div style="margin:15px;">
    <van-image width="100%" :src="qr" />
  </div>
  <p style="text-align:center;">{{ store.name }}</p>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import QRCode from 'qrcode'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const state = reactive({
      storeId: Cookies.get('storeId'),
      store: {},
      qr: ''
    })
    const init = () => {
      post('/store.get', {
        storeId: state.storeId
      }).then(res => {
        state.store = res.data
      })
      post('/util.getAllUrls', {
      }).then(res => {
        const url = res.data.shop + 'freePay?storeId=' + state.storeId
        console.log(url)
        QRCode.toDataURL(url,
          {
            width: 500,
            margin: 1,
            color: {
              // dark: '#010599FF',
              // light: '#FFBF60FF'
            }
          }
        ).then(image => {
          state.qr = image
        })
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
body {
  background:#f9f9f9;
}
</style>
